import React, { useEffect, useRef, useState } from 'react';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../../Firebase';
import { green } from '@material-ui/core/colors';
import VisitorDetails from './visitorDetails';
import VisitorMeeting from './visitorMeeting';
import VisitorMeetingConfirm from './visitorMeetingConfirm';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Box } from '@mui/material';
import CarReg from './carReg';
import ContactNumber from './contactNumber';

const useStyles = makeStyles((theme) => ({
   container: {
      height: '70vh',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: theme.spacing(8),
   },
   paper: {
      marginTop: theme.spacing(6),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(4),
   },
   green: {
      color: green[700],
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
   avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
}));

const VisitorForm = ({ customisations, ...props }) => {
   const classes = useStyles();
   const history = useHistory();
   const [status, setStatus] = useState(null);
   const [form, setForm] = useState(false);
   const [imageUrl, setImageUrl] = useState(null);

   useEffect(() => {
      const fetchImage = async () => {
         if (props.location) {
            const visitorDoc = await props.firebase.db
               .doc(`visitors/${props.location.loc}`)
               .get();
            if (visitorDoc.exists && visitorDoc.data().logoPath) {
               const fullPath = await props.firebase
                  .showImage(visitorDoc.data().logoPath)
                  .getDownloadURL();
               setImageUrl(fullPath);
            }
         }
      };
      fetchImage();
      return () => {
         setImageUrl(null);
      };
   }, [props.location]);

   useEffect(() => {
      if (props.location) {
         setStatus('step1');
      }
   }, [props.location]);

   const inactivityTimer = useRef(null);

   const resetTimer = () => {
      clearTimeout(inactivityTimer.current);
      inactivityTimer.current = setTimeout(clearForm, 30000); // 30 seconds
   };
   useEffect(() => {
      window.onload = resetTimer;
      document.onmousemove = resetTimer;
      document.onkeydown = resetTimer;
      document.ontouchstart = resetTimer; // For touch devices

      return () => {
         clearTimeout(inactivityTimer.current);
         window.onload = null;
         document.onmousemove = null;
         document.onkeydown = null;
         document.ontouchstart = null;
      };
   }, []);

   function clearForm() {
      if (props.llid) {
         setForm(false);
         history.push({
            pathname: `/visitors?llid=${props.llid}&llocation=${props.landlocation}`,
         });
         history.go(0);
      } else {
         setStatus('step1');
      }
   }

   return (
      <React.Fragment>
         <Container component="main" style={{ width: '70vw' }}>
            <Paper className={classes.paper}>
               {imageUrl !== null ? (
                  <Box
                     sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                     }}
                  >
                     <img
                        style={{
                           maxWidth: '100%',
                           maxHeight: '100px',
                           width: 'auto',
                           height: 'auto',
                        }}
                        src={imageUrl}
                     />
                     <Typography variant="h4" component="h1" align="center" gutterBottom>
                        Visitor Registration
                     </Typography>
                  </Box>
               ) : (
                  <Typography variant="h4" component="h1" align="center" gutterBottom>
                     {props.location.locName} Visitor Registration
                  </Typography>
               )}

               <Divider style={{ width: '100%', marginBottom: '8px' }} />
               {status == 'step1' ? (
                  <VisitorDetails
                     setForm={setForm}
                     setStatus={setStatus}
                     lid={props.location.loc}
                     llid={props.location.llid}
                     landlocation={props.landlocation}
                     clearForm={clearForm}
                     customisations={customisations}
                  />
               ) : status == 'step2' ? (
                  <VisitorMeeting
                     form={form}
                     setForm={setForm}
                     setStatus={setStatus}
                     lid={props.location.loc}
                     llid={props.location.llid}
                     landlocation={props.landlocation}
                     clearForm={clearForm}
                     customisations={customisations}
                     locationName={props.location.locName}
                  />
               ) : status == 'step3' ? (
                  <VisitorMeetingConfirm
                     form={form}
                     setForm={setForm}
                     setStatus={setStatus}
                     lid={props.location.loc}
                     llid={props.location.llid}
                     landlocation={props.landlocation}
                     clearForm={clearForm}
                  />
               ) : status == 'carReg' ? (
                  <CarReg
                     form={form}
                     setForm={setForm}
                     setStatus={setStatus}
                     lid={props.location.loc}
                     llid={props.location.llid}
                     landlocation={props.landlocation}
                     clearForm={clearForm}
                     customisations={customisations}
                  />
               ) : status == 'contactNumber' ? (
                  <ContactNumber
                     form={form}
                     setForm={setForm}
                     setStatus={setStatus}
                     lid={props.location.loc}
                     llid={props.location.llid}
                     landlocation={props.landlocation}
                     clearForm={clearForm}
                     customisations={customisations}
                  />
               ) : (
                  <React.Fragment>
                     <Typography variant="h5" component="h2" paragraph>
                        Loading...
                     </Typography>
                     <CircularProgress />
                  </React.Fragment>
               )}
            </Paper>
         </Container>
      </React.Fragment>
   );
};

export default withFirebase(VisitorForm);
