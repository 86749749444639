import React, { useState, useEffect } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import { withFirebase } from '../Firebase';
import { makeStyles } from '@material-ui/core/styles';
import { DateToday, getWeekDates, setupUserRow } from '../../constants/functions';
import StatusDrawer from '../Schedule/DrawerComponents/statusDrawer';
import SingleCells from '../Schedule/singleCells';

const useStyles = makeStyles((theme) => ({
   todayColumn: {
      borderColor: theme.palette.primary.main,
      borderStyle: 'solid',
      borderWidth: 1,
      borderBottomWidth: 0,
   },
   dayColumn: {},
   statusTodayColumn: {
      borderColor: theme.palette.primary.main,
      borderStyle: 'solid',
      borderWidth: 1,
      borderTopWidth: 0,
   },
   statusColumn: {},
   drawerPaper: {
      [theme.breakpoints.down('md')]: {
         width: '80%',
      },
      [theme.breakpoints.down('sm')]: {
         width: '90%',
      },
      [theme.breakpoints.down('xs')]: {
         width: '90%',
      },
      [theme.breakpoints.up('md')]: {
         width: '65%',
      },
   },
   drawer: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
}));

const MyWeek = (props) => {
   const classes = useStyles();
   const user = JSON.parse(localStorage.getItem('authUser'));
   const primaryLocation = user.lid[0];
   const [userLoc, setUserLoc] = useState(false);
   const uid = user.uid;
   let today = DateToday();
   const [coLocations, setCoLocations] = useState(false);
   const [archivedLocations, setArchivedLocations] = useState(false);
   const [row, setRow] = useState({
      id: '',
      name: 'Loading...',
      lid: '',
      cid: '',
      // date: props.weekDates,
      status: { 0: '-', 1: '-', 2: '-', 3: '-', 4: '-', 5: '-', 6: '-' },
      space: {
         0: 'empty',
         1: 'empty',
         2: 'empty',
         3: 'empty',
         4: 'empty',
         5: 'empty',
         6: 'empty',
      },
      offsiteLocation: { 0: null, 1: null, 2: null, 3: null, 4: null, 5: null, 6: null },
      clickable: false,
   });
   const [statusDrawerOpen, setStatusDrawerOpen] = useState(false);
   const [spaceData, setSpaceData] = useState({});
   const [spaces, setSpaces] = useState(null);
   const [drawerFilter, setDrawerFilter] = useState(false);
   const weekDates = getWeekDates(today);
   useEffect(() => {
      const loadCompany = async () => {
         props.firebase
            .company(user.cid)
            .get()
            .then((result) => {
               setCoLocations(result.data().locations);
               setArchivedLocations(result.data().locationsArchived);
            });
      };
      loadCompany();
   }, []);

   useEffect(() => {
      const loadMyLocation = async () => {
         //  if (displayFilter) {
         props.firebase.location(primaryLocation).onSnapshot((result) => {
            setUserLoc({ ...result.data(), id: result.id });
            setDrawerFilter({
               type: 'location',
               id: result.id,
               name: result.data().name,
               cid: result.data().cid,
            });
         });
         //  }
      };
      loadMyLocation();
   }, []);

   useEffect(() => {
      const loadMyWeek = () => {
         if (userLoc && coLocations) {
            var dates = [];
            for (var i = 0; i < 7; i++) {
               dates[i] = weekDates[i].ref;
            }
            let schedule = {};
            let dayStatus = {};
            let daySpace = {};
            let offsiteLocation = {};
            const unsubscribe = props.firebase
               .userScheduleDayStreak(props.displayFilter.cid, user.uid, dates)
               .onSnapshot((returnedDays) => {
                  returnedDays.forEach((doc) => {
                     if (doc.exists) {
                        schedule[doc.id] = doc.data();
                     }
                  });
                  let meSetup = setupUserRow(
                     schedule,
                     user,
                     weekDates,
                     userLoc,
                     coLocations,
                     archivedLocations,
                  );
                  meSetup.clickable = true;
                  setRow(meSetup);
               });
            return () => unsubscribe();
         }
      };
      loadMyWeek();
   }, [props.displayFilter, coLocations, props.routineEnabled, userLoc]);

   useEffect(() => {
      const loadSpaces = async () => {
         if (props.location.spacesEnabled) {
            await props.firebase
               .spaces(primaryLocation)
               .get()
               .then((result) => {
                  if (!result.empty) {
                     let documents = {};
                     result.forEach((doc) => {
                        documents[doc.id] = doc.data();
                     });
                     setSpaces(documents);
                  } else {
                     setSpaces(null);
                  }
               });
         }
      };
      loadSpaces();
   }, [props.location.spacesEnabled, primaryLocation]);

   function handleClose() {
      setStatusDrawerOpen(false);
   }
   return (
      <React.Fragment>
         {drawerFilter && (
            <Drawer
               classes={{ paper: classes.drawerPaper }}
               anchor="right"
               open={statusDrawerOpen}
               onClose={() => handleClose()}
               disableEnforceFocus
            >
               <StatusDrawer
                  statusDrawerOpen={statusDrawerOpen}
                  setStatusDrawerOpen={setStatusDrawerOpen}
                  data={spaceData}
                  displayFilter={drawerFilter}
                  setSpaceData={setSpaceData}
                  spacesEnabled={userLoc.spacesEnabled}
               />
            </Drawer>
         )}
         <Typography component="h2" variant="h6" color="primary" gutterBottom>
            My Week{' '}
            {props.displayFilter &&
               coLocations &&
               props.displayFilter.id !== primaryLocation &&
               ' - ' + coLocations[primaryLocation]}
         </Typography>
         <TableContainer>
            <Table>
               <TableHead>
                  <TableRow>
                     {weekDates.map((day) => {
                        if (day.dayName !== 'Sunday') {
                           return (
                              <TableCell
                                 align="center"
                                 className={
                                    day.day === today.day
                                       ? classes.todayColumn
                                       : classes.dayColumn
                                 }
                                 key={day.day}
                              >
                                 <Typography variant="subtitle1">{day.day}</Typography>
                                 <Typography variant="subtitle2">
                                    {day.dayName}
                                 </Typography>
                              </TableCell>
                           );
                        }
                     })}
                     <TableCell
                        align="center"
                        className={
                           weekDates[0].day === today.day
                              ? classes.todayColumn
                              : classes.dayColumn
                        }
                        key={weekDates[0].day}
                     >
                        <Typography variant="subtitle1">{weekDates[0].day}</Typography>
                        <Typography variant="subtitle2">
                           {weekDates[0].dayName}
                        </Typography>
                     </TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  <TableRow>
                     {Object.entries(row.status).map(([key, status]) => {
                        if (key >= 1) {
                           return (
                              <TableCell
                                 className={
                                    today.weekDay == key
                                       ? classes.statusTodayColumn
                                       : classes.statusColumn
                                 }
                                 key={key}
                                 align="center"
                              >
                                 <SingleCells
                                    key={key}
                                    spaces={spaces}
                                    setStatusDrawerOpen={setStatusDrawerOpen}
                                    setSpaceData={setSpaceData}
                                    spacesEnabled={props.location.spacesEnabled}
                                    routineEnabled={props.routineEnabled}
                                    location={userLoc}
                                    firebase={props.firebase}
                                    date={weekDates[key]}
                                    editPrevious={false}
                                    row={row}
                                    dayNo={key}
                                    space={row.space}
                                 />
                              </TableCell>
                           );
                        }
                     })}
                     <TableCell
                        className={
                           today.weekDay == 0
                              ? classes.statusTodayColumn
                              : classes.statusColumn
                        }
                        key={0}
                        align="center"
                     >
                        <SingleCells
                           key={0}
                           spaces={spaces}
                           setStatusDrawerOpen={setStatusDrawerOpen}
                           setSpaceData={setSpaceData}
                           spacesEnabled={props.location.spacesEnabled}
                           routineEnabled={props.routineEnabled}
                           location={userLoc}
                           firebase={props.firebase}
                           date={weekDates[0]}
                           editPrevious={false}
                           row={row}
                           dayNo={0}
                           space={row.space}
                        />
                     </TableCell>
                  </TableRow>
               </TableBody>
            </Table>
         </TableContainer>
      </React.Fragment>
   );
};

export default withFirebase(MyWeek);
