import { Table, TableCell, Stack, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { withFirebase } from '../../../Firebase';
import { useTheme } from '@mui/material/styles';
import { setupUserRow } from '../../../../constants/functions';
import * as ROLES from '../../../../constants/roles';
import MultiWeekSingleCell from './multiWeekSingleCell';
import { AdditionRolesIconDisplay, roleList } from '../../../AdditionalRoles';
import BusinessIcon from '@mui/icons-material/Business';
import { getName } from '../../../../utils/objectUtils';

const MultiWeekRow = (props) => {
   const theme = useTheme();
   const authUser = JSON.parse(localStorage.getItem('authUser'));
   const [row, setRow] = useState(false);
   const [personProfileRoles, setPersonProfileRoles] = useState([]);
   const [groupAdmins, setGroupAdmins] = useState({});
   const [individual, setIndividual] = useState(props.person);

   async function getPersonDetails() {
      const profileRoles = await props.firebase
         .getPerson(props.person.cid, props.person.uid)
         .get()
         .then((result) => {
            setIndividual({ ...props.person, ...result.data() });
            return result.data().profileRoles;
         });

      setPersonProfileRoles(profileRoles);
   }
   useEffect(() => {
      getPersonDetails();
   }, []);

   useEffect(() => {
      if (
         props.location &&
         authUser &&
         authUser.roles.groupAdmin &&
         props.location.groups
      ) {
         const unsubscribe = props.firebase.db
            .doc(`company/${authUser.cid}/people/${authUser.uid}/index/groupAdmin`)
            .onSnapshot((snapshot) => {
               if (snapshot.exists) {
                  setGroupAdmins(snapshot.data());
               }
            });
         return () => {
            unsubscribe();
         };
      }
   }, [props.location]);

   useEffect(() => {
      if (props.person && props.weekDates && individual) {
         setRow(false);
         const unsubscribe = props.firebase
            .userScheduleMultiDay(
               props.person.uid,
               authUser.cid,
               props.weekDates[0].ref,
               props.weekDates[props.weekDates.length - 1].ref,
            )
            .onSnapshot((snapshot) => {
               let schedule = {};
               if (!snapshot.empty) {
                  snapshot.forEach((doc) => {
                     schedule[doc.id] = doc.data();
                  });
               }
               console.log(individual, 'PRE');
               let meSetup = setupUserRow(
                  schedule,
                  individual,
                  props.weekDates,
                  props.location,
                  props.coLocations,
                  props.archivedLocations,
               );
               //This is to disable clicking on the status if using global groups
               if (meSetup.lid === props.location.id) {
                  if (authUser.roles[ROLES.ADMIN] || meSetup.id === authUser.uid) {
                     meSetup.clickable = true;
                  }

                  if (groupAdmins[meSetup.id] !== undefined) {
                     meSetup.clickable = true;
                  }
                  if (meSetup.id === authUser.uid) {
                     meSetup.currentUser = true;
                  }
               }

               setRow(meSetup);
            });
         return () => {
            unsubscribe();
            setRow(false);
         };
      }
   }, [individual, props.weekDates, groupAdmins]);

   return (
      <React.Fragment>
         <TableCell
            sx={{
               maxWidth: '200px',
               position: 'sticky',
               left: 0,
               zIndex: 99,
               backgroundColor: theme.palette.tableBody.main,
            }}
         >
            <Stack
               display={'flex'}
               alignItems={'center'}
               sx={{
                  flexDirection: ['column', 'column', 'row'],
               }}
            >
               <>
                  {individual.lid && individual.lid[0] !== props.location.id && (
                     <Tooltip title={props.coLocations[individual.lid]} placement="top">
                        <BusinessIcon fontSize="small" sx={{ color: 'grey', mr: 0.5 }} />
                     </Tooltip>
                  )}
               </>
               <span
                  style={{
                     overflow: 'hidden',
                     textOverflow: 'ellipsis',
                     whiteSpace: 'nowrap',
                     maxWidth: '190px',
                  }}
               >
                  {getName(props.person)}
               </span>
               {props.company &&
               props.company.features &&
               props.company.features.groups ? (
                  <>
                     {personProfileRoles && (
                        <AdditionRolesIconDisplay ml={1} roles={personProfileRoles} />
                     )}
                  </>
               ) : null}
            </Stack>
         </TableCell>
         {row &&
            props.weekDates &&
            props.weekDates.map((weekDay, key) => {
               if (!props.hideWeekends) {
                  return (
                     <MultiWeekSingleCell
                        key={key}
                        spaces={props.spaces}
                        setStatusDrawerOpen={props.setStatusDrawerOpen}
                        setSpaceData={props.setSpaceData}
                        spacesEnabled={props.spacesEnabled}
                        routineEnabled={props.routineEnabled}
                        location={props.location}
                        date={weekDay}
                        editPrevious={props.editPrevious}
                        row={row}
                        dayNo={key}
                        space={row.space}
                        view={props.view}
                     />
                  );
               } else if (weekDay.weekDay !== 6 && weekDay.weekDay !== 0) {
                  return (
                     <MultiWeekSingleCell
                        key={key}
                        spaces={props.spaces}
                        setStatusDrawerOpen={props.setStatusDrawerOpen}
                        setSpaceData={props.setSpaceData}
                        spacesEnabled={props.spacesEnabled}
                        routineEnabled={props.routineEnabled}
                        location={props.location}
                        date={weekDay}
                        editPrevious={props.editPrevious}
                        row={row}
                        dayNo={key}
                        space={row.space}
                        view={props.view}
                     />
                  );
               }
            })}
      </React.Fragment>
   );
};

export default withFirebase(MultiWeekRow);
