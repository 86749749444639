import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../../Firebase';
import firebase from 'firebase/app';
import { DateToday } from '../../../constants/functions';
import Avatar from '@material-ui/core/Avatar';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { green } from '@material-ui/core/colors';

import HostFound from './hostFound';

const useStyles = makeStyles((theme) => ({
   container: {
      height: '70vh',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: theme.spacing(8),
   },
   paper: {
      marginTop: theme.spacing(16),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(4),
   },
   green: {
      color: green[700],
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
   avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
   button: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      border: '1px solid #342E76',
      borderRadius: 8,
      cursor: 'pointer',
      '&:hover': {
         backgroundColor: '#342E76',
         color: 'white',
      },
   },
}));
const VisitorMeeting = ({ customisations, clearForm, locationName, ...props }) => {
   const classes = useStyles();
   const [loading, setLoading] = useState(true);
   const [host, setHost] = useState(false);
   const [name, setName] = useState('');
   const [nameError, setNameError] = useState({ status: false, text: '' });
   const [nameList, setNameList] = useState(false);
   const [checkedIn, setCheckedIn] = useState(false);
   const [multiOptions, setMultiOptions] = useState(false);
   const [unannouncedVisitor, setUnannouncedVisitor] = useState(false);
   const [checkoutData, setCheckoutData] = useState({});
   useEffect(() => {
      const checkHost = async () => {
         if (props.form) {
            let cleanEmail = props.form.email.toLowerCase();
            let found = await props.firebase
               .findHost(props.lid, DateToday().ref)
               .get()
               .then((result) => {
                  if (result.exists && cleanEmail in result.data()) {
                     return result.data()[cleanEmail];
                  } else {
                     return false;
                  }
               });
            if (found) {
               if (found.checkout) {
                  setHost({
                     ...host,
                     found: true,
                     name: found.host.name,
                     email: found.host.email,
                     checkin: found.checkin,
                     checkout: found.checkout,
                  });
               } else {
                  setHost({
                     found: true,
                     name: found.host.name,
                     email: found.host.email,
                  });
               }
               if (
                  (!found.checkin || (found.checkin && found.checkout)) &&
                  customisations.carReg &&
                  props.form.carReg == undefined
               ) {
                  props.setStatus('carReg');
               } else if (
                  (!found.checkin || (found.checkin && found.checkout)) &&
                  customisations.contactNumber &&
                  props.form.contactNumber == undefined
               ) {
                  props.setStatus('contactNumber');
               }
               if (found.checkin && !found.checkout) {
                  setCheckoutData({
                     visitorEmail: found.email,
                     visitorName: found.name,
                     hostName: found.host.name,
                     hostEmail: found.host.email,
                     locationName: locationName,
                     checkedIn: true,
                     host: true,
                  });
                  setCheckedIn(true);
               }
            } else {
               if (customisations?.carReg && props.form?.carReg == undefined) {
                  props.setStatus('carReg');
               } else if (
                  customisations?.contactNumber &&
                  props.form?.contactNumber == undefined
               ) {
                  props.setStatus('contactNumber');
               } else {
                  let employees = await props.firebase.allHosts(props.lid).get();
                  setNameList(employees.data());
                  setHost({ found: false, name: '', email: '' });
                  setUnannouncedVisitor(true);
               }
            }
            setLoading(false);
         }
      };
      checkHost();
   }, [props.form]);

   function handleSubmit() {
      if (name === '') {
         setNameError({ status: true, text: 'You must enter a host name or email' });
      } else {
         let foundNames = {};
         Object.entries(nameList).map(([key, value]) => {
            if (
               value.toLowerCase() == name.toLowerCase() ||
               key.toLowerCase() == name.toLowerCase()
            ) {
               foundNames[key] = value;
            }
         });
         if (Object.keys(foundNames).length === 1) {
            setHost({
               found: true,
               name: Object.values(foundNames)[0],
               email: Object.keys(foundNames)[0],
            });
         } else if (Object.keys(foundNames).length > 1) {
            setMultiOptions(foundNames);
            setNameError({ status: false, text: '' });
         } else {
            setNameError({
               status: true,
               text: "Oops, we can't find these details in our host list. Please call your host if you are struggling to register.",
            });
         }
      }
   }

   async function checkout() {
      setLoading(true);
      try {
         await props.firebase.visitorCheckin(
            props.form,
            props.lid,
            DateToday().ref,
            'checkout',
         );
         var email = firebase
            .functions()
            .httpsCallable('sendgridVisitors-sendgridVisitors');
         await email(checkoutData);
      } catch (error) {
         console.warn(error, 'error');
      }
      setLoading(false);
      props.setStatus('step3');
   }

   return (
      <React.Fragment>
         {checkedIn && (
            <Typography variant="h5" component="h2">
               Leaving?
            </Typography>
         )}
         {loading ? (
            <React.Fragment>
               <Typography variant="h5" component="h2" paragraph>
                  Loading...
               </Typography>
               <CircularProgress />
            </React.Fragment>
         ) : checkedIn ? (
            <React.Fragment>
               <Typography variant="h6" component="h3">
                  Thank you for visiting
               </Typography>
               <Typography variant="body1" component="p">
                  Please click on Check Out below to complete your visit.
               </Typography>
               <Button
                  onClick={() => {
                     checkout();
                  }}
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
               >
                  Check Out
               </Button>
            </React.Fragment>
         ) : host.found ? (
            <HostFound
               host={host}
               setHost={setHost}
               setStatus={props.setStatus}
               clearForm={clearForm}
               customisations={customisations}
               form={props.form}
               lid={props.lid}
               setLoading={setLoading}
               unannouncedVisitor={unannouncedVisitor}
               locationName={locationName}
            />
         ) : (
            <React.Fragment>
               <Typography variant="body1" component="p">
                  Please enter the name or email address of your host below.
               </Typography>
               <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Name / Email"
                  name="name"
                  value={name}
                  onInput={(e) => {
                     setName(e.target.value);
                  }}
                  error={nameError.status}
                  helperText={nameError.text}
               />
               <Button
                  onClick={() => {
                     handleSubmit();
                  }}
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
               >
                  Next
               </Button>
               <Button
                  onClick={() => {
                     clearForm();
                  }}
                  fullWidth
                  variant="outlined"
                  color="primary"
               >
                  start again
               </Button>
               {multiOptions && (
                  <React.Fragment>
                     <Typography variant="body1" component="p">
                        We found multiple people with that name, please click on your
                        correct host's name.
                     </Typography>
                     {Object.entries(multiOptions).map(([key, value]) => (
                        <div
                           onClick={() => {
                              setHost({ found: true, name: value, email: key });
                           }}
                           className={classes.button}
                           key={key}
                        >
                           <Typography variant="body1" align="center">
                              {value}
                           </Typography>
                           <Typography variant="caption" align="center">
                              {key}{' '}
                           </Typography>
                        </div>
                     ))}
                  </React.Fragment>
               )}
            </React.Fragment>
         )}
      </React.Fragment>
   );
};
export default withFirebase(VisitorMeeting);
